<template>
    <div class="auth-wrapper auth-v2">
        <div class="auth-inner">
            <!-- brand logo -->
            <router-link class="brand-logo d-flex align-center" to="/">
                <v-img :src="appLogo" alt="logo" class="me-3 " contain max-height="30px" max-width="30px"></v-img>
                <h2 class="text--primary"> {{ appName }} </h2>
            </router-link>
            <!--/ brand logo -->
            <v-row class="auth-row ma-0">
                <v-col class="d-none d-lg-block position-relative overflow-hidden pa-0" lg="8">
                    <div class="auth-illustrator-wrapper">
                        <!-- triangle bg -->
                        <img :src="require(`@/assets/images/misc/mask-v2-${$vuetify.theme.dark ? 'dark' : 'light'}.png`)"
                            class="auth-mask-bg" height="362" />
                        <!-- tree -->
                        <v-img class="auth-tree" height="226" src="@/assets/images/misc/tree-2.png" width="300"></v-img>
                        <!-- 3d character -->
                        <div class="d-flex align-center h-full pa-16 pe-0">
                            <v-img
                                :src="require(`@/assets/images/3d-characters/illustration-register-v2-${$vuetify.theme.dark ? 'dark' : 'light'}.png`)"
                                class="auth-3d-group" contain height="710" max-width="100%"></v-img>
                        </div>
                    </div>
                </v-col>
                <v-col class="d-flex align-center auth-bg pa-10 pb-0" lg="4">
                    <v-row>
                        <v-col class="mx-auto" cols="12" lg="12" md="6" sm="8">
                            <v-card flat>
                                <v-card-text>
                                    <p class="text-2xl font-weight-semibold text--primary my-2"> Adventure starts here 🚀
                                    </p>
                                    <p class="mb-2"> Make your app management easy and fun! </p>
                                </v-card-text>
                                <!-- login form -->
                                <v-card-text>
                                    <v-form ref="registerForm" @submit.prevent="handleFormSubmit">
                                        <v-text-field v-model="username" :error-messages="errorMessages.username"
                                            :rules="[validators.required, validators.alphaValidator]" class="mb-6"
                                            hide-details="auto" label="Username" outlined
                                            placeholder="Username"></v-text-field>
                                        <v-text-field v-model="email" :error-messages="errorMessages.email"
                                            :rules="[validators.required, validators.emailValidator]" class="mb-6"
                                            hide-details="auto" label="Email" outlined placeholder="Email"></v-text-field>
                                        <v-text-field v-model="password"
                                            :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
                                            :error-messages="errorMessages.password"
                                            :rules="[validators.required, validators.passwordValidator]"
                                            :type="isPasswordVisible ? 'text' : 'password'" class="mb-2" hide-details="auto"
                                            label="Password" outlined placeholder="Password"
                                            @click:append="isPasswordVisible = !isPasswordVisible"></v-text-field>
                                        <v-checkbox class="mt-0" hide-details>
                                            <template #label>
                                                <div class="d-flex align-center flex-wrap"><span class="me-2">I agree
                                                        to</span><a href="javascript:void(0)">privacy policy &amp; terms</a>
                                                </div>
                                            </template>
                                        </v-checkbox>
                                        <v-btn block class="mt-6" color="primary" type="submit"> Sign Up</v-btn>
                                    </v-form>
                                </v-card-text>
                                <!-- create new account  -->
                                <v-card-text class="d-flex align-center justify-center flex-wrap mt-2">
                                    <p class="mb-0 me-2"> Already have an account? </p>
                                    <router-link :to="{ name: 'auth-login' }"> Sign in instead</router-link>
                                </v-card-text>
                                <!-- divider -->
                                <v-card-text class="d-flex align-center mt-2">
                                    <v-divider></v-divider>
                                    <span class="mx-5">or</span>
                                    <v-divider></v-divider>
                                </v-card-text>
                                <!-- social links -->
                                <v-card-actions class="d-flex justify-center">
                                    <v-btn v-for="link in socialLink" :key="link.icon" class="ms-1" icon>
                                        <v-icon :color="$vuetify.theme.dark ? link.colorInDark : link.color"> {{ link.icon
                                        }}</v-icon>
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </div>
    </div>
</template>
<script>
// eslint-disable-next-line object-curly-newline
import { mdiEyeOffOutline, mdiEyeOutline, mdiFacebook, mdiGithub, mdiGoogle, mdiTwitter } from '@mdi/js'
// eslint-disable-next-line object-curly-newline
import { alphaValidator, emailValidator, passwordValidator, required } from '@core/utils/validation'
import { getCurrentInstance, ref } from '@vue/composition-api'
import axios from '@axios'
import { useRouter } from '@core/utils'
import themeConfig from '@themeConfig'

export default {
    setup() {
        // Template Refs
        const registerForm = ref(null)
        const vm = getCurrentInstance().proxy
        const { router } = useRouter()
        const isPasswordVisible = ref(false)
        const username = ref('johnDoe')
        const email = ref('john@example.com')
        const password = ref('john@MATERIO#123')
        const errorMessages = ref([])
        const socialLink = [{ icon: mdiFacebook, color: '#4267b2', colorInDark: '#4267b2', }, { icon: mdiTwitter, color: '#1da1f2', colorInDark: '#1da1f2', }, { icon: mdiGithub, color: '#272727', colorInDark: '#fff', }, {
            icon: mdiGoogle,
            color: '#db4437',
            colorInDark: '#db4437',
        },]
        const handleFormSubmit = () => {
            const isFormValid = registerForm.value.validate()
            if (!isFormValid) return  /* 1. Make HTTP request to get accessToken (Register the user & login) (User will be added to the database and new generated access token will be sent for loggin in) 2. Store received token in localStorage for future use 3. Make another HTTP request for getting user information 4. On successful response of user information redirect to home page  ? We have use promise chaining to get user data from access token ? Promise Chaining: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Using_promises#chaining */  axios.post('/auth/register', {
                username: username.value,
                email: email.value,
                password: password.value
            }).then(response => {
                const { accessToken } = response.data
                // ? Set access token in localStorage so axios interceptor can use it
                // Axios Interceptors: https://github.com/axios/axios#interceptors
                localStorage.setItem('accessToken', accessToken)
                return response
            }).then(() => {
                axios.get('/auth/me').then(response => {
                    const { user } = response.data
                    const { ability: userAbility } = user
                    // Set user ability
                    // ? https://casl.js.org/v5/en/guide/intro#update-rules
                    vm.$ability.update(userAbility)
                    // ? Set user's ability in localStorage for Access Control
                    localStorage.setItem('userAbility', JSON.stringify(userAbility))
                    // ? We will store `userAbility` in localStorage separate from userData
                    // ? Hence, we are just removing it from user object
                    delete user.ability
                    // ? Set user's data in localStorage for UI/Other purpose
                    localStorage.setItem('userData', JSON.stringify(user))
                    // ? On success redirect to home
                    router.push('/')
                })
            }).catch(error => {
                console.error('Oops, Unable to Register!')
                console.log('error :>> ', error.response)
                errorMessages.value = error.response.data.error
            })
        }
        return {
            isPasswordVisible, username, email, password, errorMessages, handleFormSubmit, socialLink, icons: { mdiEyeOutline, mdiEyeOffOutline, }, validators: { required, emailValidator, passwordValidator, alphaValidator, },
            // themeConfig
            appName: themeConfig.app.name, appLogo: themeConfig.app.logo,
            // Template Refs
            registerForm,
        }
    },
} </script>
<style lang="scss" scoped> @import '@core/preset/preset/pages/auth.scss';</style>
